<template>
  <div class="wujie-select-video">
    <vh-dialog
      title="选择音视频"
      @closed="closeHandler"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      width="800px"
    >
      <div class="search" v-show="total || isSearch">
        <vh-input
          placeholder="请输入音视频名称"
          v-model="keyWords"
          v-clearEmoij
          @keyup.enter.native="searchHandler"
          clearable
          @clear="searchHandler"
        >
          <i class="vh-icon-search vh-input__icon" @click="searchHandler" slot="prefix"></i>
        </vh-input>
        <!-- <i class="iconfont-v3 saasicon_help_m"></i> -->
        <span class="select-video_tip">为不影响观看，建议文件分辨率在1280*720p及以下</span>
        <vh-button type="primary" @click="uploadHandler" round size="medium">上传</vh-button>
      </div>
      <div v-if="total || isSearch" style="min-height: 300px">
        <vh-radio-group v-model="currentSelect">
          <vh-table
            ref="docList"
            :data="docList"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="300"
            v-loadMore="moreLoadData"
            @select="selectChange"
            @select-all="selectAllChange"
          >
            <vh-table-column width="60" v-if="!isMultiSelect">
              <template slot-scope="scope">
                <vh-radio
                  :label="scope.row[videoCheckedKey]"
                  :disabled="scope.row.transcode_status != 1 || scope.row.isDisabled"
                ></vh-radio>
              </template>
            </vh-table-column>
            <vh-table-column
              :selectable="selectEnable"
              type="selection"
              width="60"
              v-if="isMultiSelect"
            ></vh-table-column>
            <vh-table-column show-overflow-tooltip label="音视频名称">
              <template slot-scope="scope">
                <span class="mediaName">
                  <i
                    class="iconfont-v3 saasyinpinwenjian"
                    v-if="scope.row.msg_url == '.MP3' || scope.row.msg_url == '.MAV'"
                  ></i>
                  <i class="iconfont-v3 saasshipinwenjian" v-else></i>
                  {{ scope.row.name }}
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="上传日期" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.created_at.substring(0, 16) }}</span>
              </template>
            </vh-table-column>

            <vh-table-column
              label="时长"
              prop="duration"
              width="90"
              show-overflow-tooltip
            ></vh-table-column>

            <vh-table-column label="进度" width="110" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  class="statusTag"
                  :class="scope.row.transcode_status == 1 ? 'success' : 'failer'"
                >
                  {{ scope.row.transcode_status_text }}
                </span>
                <!-- <vh-progress v-if="scope.row.status=='transcoding'" color="#14BA6A" :percentage="scope.row.process" :stroke-width="8" :width="100"></vh-progress>
            <span v-else :class="[scope.row.status, 'statusTag']">{{scope.row.status | statusStr}}</span> -->
              </template>
            </vh-table-column>

            <vh-table-column label="操作" width="70" align="left" show-overflow-tooltip>
              <template slot-scope="scope">
                <vh-button
                  type="text"
                  class="actionBtn"
                  @click="preVidio(scope.row)"
                  v-if="scope.row.transcode_status == 1"
                >
                  预览
                </vh-button>
              </template>
            </vh-table-column>
            <div slot="empty">
              <noData :nullType="'search'" v-if="!total" :height="50"></noData>
            </div>
          </vh-table>
        </vh-radio-group>
      </div>
      <div class="no-live" v-else>
        <noData :nullType="'nullData'" text="您还未上传过音视频，快来创建吧！" :height="0">
          <vh-button type="primary" @click="uploadHandler" round size="medium">上传</vh-button>
        </noData>
      </div>
      <div slot="footer" class="dialog-footer" v-show="total || isSearch">
        <!-- <div>
          <p>
            当前选择
            <b>{{ currentSelect ? 1 : 0 }}</b>
            个文件
          </p>
        </div> -->
        <span>
          <vh-button type="info" @click="closeDialog" round plain size="medium">取消</vh-button>
          <vh-button
            type="primary"
            @click="handlerConfirm"
            :disabled="
              isMultiSelect ? !selections.length : !currentSelect || checkedList[0] == currentSelect
            "
            round
            size="medium"
            v-preventReClick
          >
            确定
          </vh-button>
        </span>
      </div>
    </vh-dialog>
    <template v-if="showDialog">
      <vh-dialog
        class="vh-saas-dialog"
        title=""
        :visible.sync="showDialog"
        width="30%"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <video-preview ref="videoPreview" :videoParam="videoParam"></video-preview>
      </vh-dialog>
    </template>
  </div>
</template>

<script>
  import VideoPreview from '@/components/VideoPreview';
  import noData from '@/views/PlatformModule/Error/nullPage';
  // 转码状态
  const TRANSCODE_STATUS_MAP = {
    0: { transcode_status_text: '新增排队中', duration: '——' },
    1: { transcode_status_text: '转码成功' },
    2: { transcode_status_text: '转码失败', duration: '——' },
    3: { transcode_status_text: '转码中', duration: '——' }
  };
  export default {
    name: 'wujieSelectVideo',
    props: {
      videoSize: {
        type: String,
        default: ''
      },
      videoType: {
        type: String,
        default: ''
      },
      // 选中的数据
      checkedList: {
        required: false,
        type: Array,
        default: () => []
      },
      // 禁选的数据
      disabledList: {
        required: false,
        type: Array,
        default: () => []
      },
      videoCheckedKey: {
        type: String,
        default: 'id'
      },
      videoEditId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        dialogVisible: false,
        showDialog: false,
        videoParam: {},
        docList: [],
        total: 0,
        isSearch: false, //是否是搜索
        pageInfo: {
          pos: 0,
          limit: 6,
          pageNum: 1
        },
        totalPages: 0,
        totalWarmSelect: [],
        keyWords: '',
        currentSelect: null, // 单选选中的数据
        selections: [], // 多选最终结果数据
        isMultiSelect: false, // 是否是多选
        isOpeated: false // 是否操作过列表
      };
    },
    components: {
      VideoPreview,
      noData
    },
    watch: {
      dialogVisible() {
        //获取资料中心的音视频
        if (this.dialogVisible) {
          this.docList = [];
          // 初始化选中的数据
          if (this.checkedList && this.checkedList.length) {
            this.currentSelect = isNaN(Number(this.checkedList[0]))
              ? this.checkedList[0]
              : Number(this.checkedList[0]);
          }
          this.pageInfo.pageNum = 1;
          this.pageInfo.pos = 0;
          this.getMediaList();
        } else {
          this.isOpeated = false;
          this.keyWords = '';
          this.pageInfo.pageNum = 1;
          this.pageInfo.pos = 0;
          this.totalWarmSelect = [];
          this.currentSelect = null;
          this.selections = [];
          // if (this.docList.length) {
          //   this.$refs.docList.clearSelection();
          // }
        }
      }
    },
    methods: {
      open(isMultiSelect) {
        this.dialogVisible = true;
        this.isMultiSelect = isMultiSelect;
      },
      selectChange(selection, row) {
        row.checked = !row.checked;
        this.syncSelections([row], 2);
        this.isOpeated = true;
      },
      selectAllChange(list) {
        this.syncSelections(this.docList, list.length ? 0 : 1);
        this.docList.forEach(item => {
          item.checked = !!list.length;
        });
        this.isOpeated = true;
      },
      // list 是变更的数组， type 代表变更类型：0 新增、1 删除、2 自动匹配。
      // 自动匹配的规则：原来有的删除、原来没有的新增
      // 这里特别注意的是，selections 中可能存在 docList 中不存的的元素（选中之后进行搜索会出现这种场景）
      syncSelections(list, type) {
        if (type === 0) {
          // 合并、去重
          // 不直接使用 docList 赋值的原因是，selections 中可能存在 docList 中不存的的元素（选中之后进行搜索会出现这种场景）
          this.selections = this.selections.concat(list);
          this.selections = this.selections.reduce((preVal, curVal) => {
            // selections 中存的是本次新增的元素，已经绑定的元素不需要添加到 selections 中
            if (
              !this.checkedList.includes(curVal[this.videoCheckedKey]) &&
              !this.isHasItem(preVal, curVal)
            ) {
              preVal.push(curVal);
            }
            return preVal;
          }, []);
        }

        if (type === 1) {
          // 存在就删除
          this.selections = this.selections.filter(select => !this.isHasItem(list, select));
        }

        if (type === 2) {
          // 存在就删除，不存在就添加
          list.forEach(item => {
            if (this.isHasItem(this.selections, item)) {
              this.selections = this.selections.filter(
                select => select[this.videoCheckedKey] != item[this.videoCheckedKey]
              );
            } else {
              this.selections.push(item);
            }
          });
        }
      },
      isHasItem(list = [], checkItem) {
        return list.some(item => item[this.videoCheckedKey] == checkItem[this.videoCheckedKey]);
      },
      //字数截断显示省略号
      fontNumber(date) {
        const length = date.length;
        const format = date.substring(date.length - 4, date.length);
        if (length > 13) {
          var str = '';
          str = date.substring(0, 13) + '...' + format;
          return str;
        } else {
          return date;
        }
      },
      closeDialog() {
        if (this.docList.length) {
          this.$refs.docList.clearSelection();
        }
        this.dialogVisible = false;
        this.totalWarmSelect = [];
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
      },
      handleClose(done) {
        if (this.docList.length) {
          this.$refs.docList.clearSelection();
        }
        this.dialogVisible = false;
        this.totalWarmSelect = [];
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        done();
      },
      moreLoadData() {
        if (this.pageInfo.pageNum >= this.totalPages) {
          return false;
        }
        this.pageInfo.pageNum++;
        this.pageInfo.pos = parseInt((this.pageInfo.pageNum - 1) * this.pageInfo.limit);
        this.getMediaList();
      },
      getMediaList() {
        let params = {
          title: this.keyWords,
          storage: this.videoSize,
          file_type: this.videoType,
          get_no_trans: 1,
          ...this.pageInfo
        };
        this.$fetch('dataVideoList', this.$params(params)).then(res => {
          if (res.code == 200) {
            res.data.list = this.setCustomProps(res.data.list);
            this.docList.push(...res.data.list);
            this.total = res.data.total;
            this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
            this.isSearch = this.keyWords ? true : false;
            this.setSelectedStatus();
          }
        });
      },
      setCustomProps(list) {
        if (list && list.length) {
          return list.map(item => {
            return {
              ...item,
              ...TRANSCODE_STATUS_MAP[item.transcode_status || 0], // 转码状态
              isDisabled: this.disabledList.includes(item[this.videoCheckedKey] + ''), // 是否禁用
              checked:
                this.checkedList.includes(item[this.videoCheckedKey] + '') ||
                this.isHasItem(this.selections, item) // 是否选中
            };
          });
        }
        return list;
      },
      selectEnable(row) {
        return !row.isDisabled;
      },
      setSelectedStatus() {
        this.$nextTick(() => {
          this.docList.forEach(item => {
            if (item.checked) {
              this.$refs.docList.toggleRowSelection(item, true);
            }
          });
        });
      },
      preVidio(rows) {
        this.videoParam = rows;
        this.showDialog = true;
        console.log(rows);
      },
      // 单选
      handleSelection(item) {
        console.log(item, '---------');
        this.currentSelect = item[this.videoCheckedKey];
      },
      handlerConfirm() {
        let res = null;
        if (this.isMultiSelect) {
          res = this.selections;
        } else {
          this.docList.map(item => {
            if (item[this.videoCheckedKey] == this.currentSelect) {
              res = item;
            }
          });
        }
        this.$emit('selected', res);
        this.dialogVisible = false;
      },
      closeHandler() {
        // clearSelection
      },
      searchHandler() {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        this.docList = [];
        this.getMediaList();
      },
      uploadHandler() {
        this.$vhConfirm('上传资源会离开当前页面，将丢失已编辑信息，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,

          roundButton: true,
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            // this.$router.push({ path: '/material/video' });
            let routeUrl = this.$router.resolve({ path: '/material/video' });
            window.open(routeUrl.href, '_blank');
          })
          .catch(() => {});
      }
    },
    filters: {
      statusStr(val) {
        let str = '';
        switch (val) {
          case 'wating':
            str = '等待转码';
            break;

          case 'success':
            str = '转码成功';
            break;

          case 'failer':
            str = '等待失败';
            break;

          default:
            str = val;
            break;
        }
        return str;
      }
    }
  };
</script>

<style lang="less" scoped>
  @red: #fb3a32;
  @redBg: #ffebeb;
  .wujie-select-video {
    max-width: 800px;
    .vh-dialog {
      max-width: 800px;
    }
  }
  .saasicon_help_m {
    padding-left: 5px;
    font-size: 15px;
    color: #999;
  }
  .select-video_tip {
    color: #999;
    padding-left: 5px;
    font-size: 14px;
    vertical-align: text-top;
  }
  .statusTag {
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
    &.wating::before {
      background: #fa9a32;
    }
    &.success::before {
      background: #14ba6a;
    }
    &.failer::before {
      background: #fb3a32;
    }
  }
  .mediaName {
    font-size: 14px;
    color: #1a1a1a;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .iconfont-v3 {
      font-size: 20px;
      margin-top: -3px;
    }
    .saasyinpinwenjian {
      color: #10d3a8;
      // padding-right: 2px;
    }
    .saasshipinwenjian {
      color: #ff733c;
      // padding-right: 2px;
    }
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      // background: #10D3A8;
      border-radius: 4px;
      // margin-right: 12px;
      vertical-align: middle;
    }
  }

  .search {
    margin-bottom: 16px;
    .vh-input {
      width: 220px;
    }
    .vh-button {
      float: right;
    }
  }
  // .vh-saas-dialog {}
  .dialog-footer {
    p {
      font-size: 14px;
      color: #666;
      line-height: 36px;
      b {
        color: #fb3a32;
        font-weight: normal;
      }
    }
  }
</style>
<style lang="less">
  .wujie-select-video {
    .vh-radio-group {
      width: 100%;
      max-width: 100%;
    }
    .vh-radio__label {
      display: none;
    }
  }
</style>
