<template>
  <div class="wujieBox">
    <!--单例模式，name相同则复用一个无界实例，改变url则子应用重新渲染实例到对应路由 -->
    <WujieVue width="100%" height="100%" name="cms" :url="cmsUrl" :props="{ jump }"></WujieVue>
    <!-- 音视频 -->
    <wujieSelectVideo
      ref="selecteMedia"
      @selected="
        data => {
          eventSelected(data, 'media');
        }
      "
      :checkedList="selectedVideos"
      :videoSize="videoSize"
      :videoType="videoType"
      :videoCheckedKey="videoCheckedKey"
      :disabledList="disabledList"
    ></wujieSelectVideo>
    <!-- 文档列表 -->
    <wujieSelectWord
      ref="selecteWord"
      :checkedList="selectedWords"
      @selected="
        data => {
          eventSelected(data, 'word');
        }
      "
    ></wujieSelectWord>
    <!-- 表单 -->
    <wujieSelectForm
      ref="selecteForm"
      :checkedList="selectedForms"
      @selected="
        data => {
          eventSelected(data, 'form');
        }
      "
    ></wujieSelectForm>
    <!-- 直播 -->
    <wujieSelectLive
      ref="selectLive"
      :checkedList="selectedLives"
      :disabledList="disabledList"
      :liveEditId="liveEditId"
      @selected="
        data => {
          eventSelected(data, 'live');
        }
      "
    ></wujieSelectLive>
    <!-- 专题 -->
    <wujieSelectSubject
      ref="selectSubject"
      :checkedList="selectedSubjects"
      :webinar_id="webinar_id"
      :subjectType="subjectType"
      @selected="
        data => {
          eventSelected(data, 'subject');
        }
      "
    ></wujieSelectSubject>
    <!-- 渠道列表 -->
    <wujieSelectChannel
      ref="selecteChannel"
      :source_type="source_type"
      :source_id="webinar_id"
      :scene_type="scene_type"
      :zIndex="zIndex || null"
      :checkedList="selectedChannels"
      @selected="
        data => {
          eventSelected(data, 'channel');
        }
      "
    ></wujieSelectChannel>
    <wujieChannelFans
      ref="channelFans"
      :source_type="source_type"
      :source_id="webinar_id"
      :scene_type="scene_type"
      :zIndex="zIndex || null"
    ></wujieChannelFans>
    <!-- 海报列表 -->
    <wujieSelectPoster
      ref="selectePoster"
      :channel_code="channel_code"
      :source_id="webinar_id"
      :source_type="source_type"
      :posterName="posterName"
      :qrcodeUrl="qrcodeUrl"
      :zIndex="zIndex"
    ></wujieSelectPoster>
    <WujieInvitationCard
      ref="invitationCard"
      :cardId="inviteCard.cardId"
      :defaultCardInfo="inviteCard.defaultCardInfo"
      :sourceId="inviteCard.inviteSourceId"
      :sourceType="inviteCard.inviteSourceType"
      :shareUrl="inviteCard.shareUrl"
      :languageType="inviteCard.languageType"
      @inviteCardSave="inviteCardSave"
    ></WujieInvitationCard>
    <div
      class="wujie-loading"
      v-loading="isShowLoading"
      v-if="isShowLoading"
      element-loading-background="rgba(247,247,247,.7)"
    ></div>
  </div>
</template>

<script>
  import cmsMixin from './mixin';
  export default {
    mixins: [cmsMixin]
  };
</script>
<style lang="less">
  .wujieBox {
    width: 100%;
    height: 100%;
    position: relative;
    .wujie-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
</style>
