<template>
  <div class="wujie-select-poster">
    <VhallDialog
      title="邀约海报"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :center="true"
      :z-index="zIndex ? zIndex : null"
      width="450px"
    >
      <vh-tabs
        v-model="linkType"
        @tab-click="linkTabClick"
        v-if="userPermission && userPermission['cms.show_global_embed_link']"
      >
        <vh-tab-pane label="活动链接" name="link"></vh-tab-pane>
        <vh-tab-pane label="全局嵌入链接" name="embedLink"></vh-tab-pane>
      </vh-tabs>
      <div v-if="(linkType === 'embedLink' && businessSiteLink) || linkType === 'link'">
        <div class="posters" v-if="list.length != 0 && switch_status != 0">
          <vh-carousel
            type="card"
            height="333px"
            :loop="true"
            :autoplay="false"
            trigger="click"
            :indicator-circle="true"
            @change="changePoster"
          >
            <vh-carousel-item v-for="(item, index) in list" :key="index">
              <invitation-card-tmpl
                :ref="'inviteCardTmplComp' + index"
                class="invitation-card-pc"
                :cardInfo="item.cardInfo"
                :positionStyle="item.positionStyle || {}"
              ></invitation-card-tmpl>
            </vh-carousel-item>
          </vh-carousel>
          <vh-button type="primary" :disabled="loading" round class="btn" @click="doDownload">
            {{ loading ? '下载中' : '下载邀约海报' }}
          </vh-button>
        </div>
        <div class="noData" v-else>
          <null-page class="search-no-data" :height="20" text="" nullType="poster">
            <div class="text">
              请先设置邀约海报
              <span @click="toPoster">去设置</span>
            </div>
          </null-page>
        </div>
      </div>
      <div v-else>
        <noData
          null-type="setting"
          text="未设置‘全局嵌入链接’，设置后可使用嵌入链接预览、分发活动"
          :height="20"
        >
          <router-link target="_blank" :to="{ path: '/cms/embed-setting/link' }">
            <vh-button type="primary" round>去设置</vh-button>
          </router-link>
        </noData>
      </div>
    </VhallDialog>
  </div>
</template>

<script>
  import { InvitationCardTmpl } from '@vhcl/invitation-card';
  import '@vhcl/invitation-card/dist/css/index.css';
  import NullPage from '@/views/PlatformModule/Error/nullPage.vue';
  import html2canvas from 'html2canvas';
  import noData from '@/components/NullPage';
  import { DEP_TYPE_NUM } from '@/common/js/constant';
  import { isEmbed } from '../../utils/utils';
  export default {
    name: 'wujieSelectPoster',
    components: {
      InvitationCardTmpl,
      NullPage,
      noData
    },
    props: {
      source_type: {
        type: Number,
        default: 1
      },
      source_id: {
        type: String,
        default: ''
      },
      channel_code: {
        type: String,
        default: ''
      },
      posterName: {
        type: String,
        default: ''
      },
      qrcodeUrl: {
        type: String,
        default: ''
      },
      zIndex: {
        type: Number,
        default: 0
      },
      userPermission: {
        type: Object, // 用户权限
        default: () => {}
      },
      businessSiteLink: {
        // 全局嵌入链接
        type: String,
        default: ''
      }
    },
    data() {
      return {
        dialogVisible: false,
        curIndex: 0,
        list: [],
        loading: false,
        switch_status: 0,
        canvasCache: [], //缓存canvas
        resCache: [], //缓存邀约海报接口返回
        needInit: true, //新旧数据是否相等
        linkType: 'link'
      };
    },
    methods: {
      linkTabClick() {
        this.getInviteCardInfo();
      },
      toPoster() {
        let { href } = this.$router.resolve({ path: '/live/invCard/' + this.source_id });
        window.open(href, '_blank');
      },
      openInit() {
        this.dialogVisible = true;
        this.linkType = 'link';
        this.getInviteCardInfo();
      },
      getInviteCardInfo() {
        let params = {
          source_id: this.source_id,
          source_type: this.source_type, //  1=直播活动 2=营销活动eventhub 3=直播专题
          language_type: 0 //   0.中文邀请卡，1.英文邀请卡
        };
        // event 不区分中英文
        if (this.source_type == 2) {
          delete params.language_type;
        }
        const api = this.source_type != 2 ? 'getCardDetailInfo' : 'getCardDetailInfoAll';
        this.$fetch(api, params, {
          'Content-Type': 'application/json'
        })
          .then(res => {
            let ress = [];
            if (this.source_type != 2) {
              ress.push(res.data);
            } else {
              ress = [].concat(res.data);
            }
            this.list = [];
            ress.map(e => {
              if (this.source_type != 2) {
                this.switch_status = e.switch_status;
              } else {
                this.switch_status = true;
              }
              let qrcodeUrl = ''; // 二维码
              if (this.linkType === 'link') {
                qrcodeUrl =
                  this.qrcodeUrl ||
                  `${process.env.VUE_APP_WAP_WATCH}/lives/watch/${this.source_id}?invite_code=${e.invite_code}&invite_way=1&channel=${this.channel_code}`;
              } else {
                const queryStr = encodeURIComponent(
                  `embedMode=1&dep_id=${this.$route.params.str}&dep_type=${DEP_TYPE_NUM['webinar']}&invite_code=${e.invite_code}&invite_way=1&channel=${this.channel_code}`
                );
                qrcodeUrl = `${this.businessSiteLink}?dep_params=${queryStr}`;
              }

              this.list = this.list.concat(
                e.templates
                  .filter(item => item.is_show == 1)
                  .map(item => {
                    return {
                      cardInfo: {
                        seq: item?.template_type == 0 ? item.theme_remark : 0,
                        bgUrl: item.img_url || '', //模板背景
                        avatar: e.head_url || '', //用户头像
                        nickname: e.nick_name || '', //用户昵称
                        poster: e.cover_img || '', //活动封面
                        title: e.title || '',
                        time: e.date_time || '',
                        addr: e.location || '',
                        code: qrcodeUrl || '',
                        isShowUserinfo: item?.is_show_userinfo, //是否展示用户信息 0.不显示 1.显示
                        isShowQrcode: item?.is_show_qrcode, //是否展示二维码 0.不显示 1.显示
                        themeType: item?.theme_type,
                        language: e.language_type ? e.language_type : 0 //语言类型  0.中文  1.英文
                      },
                      positionStyle: item?.template_json ? JSON.parse(item?.template_json) : {}
                    };
                  })
              );
            });
          })
          .finally(e => {
            this.$nextTick(() => {
              this.dialogVisible = true;
              if (
                this.resCache.length == 0 ||
                (this.resCache.length != 0 &&
                  JSON.stringify(this.resCache) == JSON.stringify(this.list))
              ) {
                this.needInit = false;
                this.resCache = [].concat(this.list);
              } else {
                this.needInit = true;
              }
              // setTimeout(() => {
              //   this.preDownloadImg(0);
              // }, 100);
            });
          });
      },
      changePoster(index) {
        this.curIndex = index;
      },
      // preDownloadImg(index) {
      //   const l = this.list.length;
      //   // 非首次
      //   if (index < l) {
      //     if (
      //       this.canvasCache[index] &&
      //       (this.resCache.length == 0 ||
      //         (this.resCache.length != 0 &&
      //           JSON.stringify(this.resCache) == JSON.stringify(this.list)))
      //     ) {
      //       this.preDownloadImg(++index);
      //     } else {
      //       const el = this.$refs['inviteCardTmplComp' + index][0]?.$el;
      //       if (!el) {
      //         return;
      //       }
      //       html2canvas(el, {
      //         timeout: 500,
      //         allowTaint: true,
      //         useCORS: true,
      //         logging: false,
      //         width: Math.round(el.offsetWidth),
      //         height: Math.round(el.offsetHeight),
      //         background: '#fff',
      //         scale: 1,
      //         ignoreElements(e) {
      //           console.dir(e);
      //           // if (e.tagName === 'LINK') {
      //           //   return true;
      //           //   // if (e.href.indexOf('invitation-card-tmpl') > -1) {
      //           //   //   return false;
      //           //   // } else {
      //           //   //   return true;
      //           //   // }
      //           // }
      //           if (e.tagName === 'SCRIPT') {
      //             // if (e.src.indexOf('localhost') > -1) {
      //             //   return false;
      //             // } else {
      //             return true;
      //             // }
      //           }
      //           return false;
      //         }
      //       }).then(canvas => {
      //         const canvasImgUrl = canvas.toDataURL();
      //         this.canvasCache[index] = canvasImgUrl;
      //         console.log(index);
      //         this.preDownloadImg(++index);
      //       });
      //     }
      //   } else {
      //     this.resCache = [].concat(this.list);
      //   }
      // },
      doDownload() {
        this.loading = true;
        // let i = setInterval(() => {
        if (this.canvasCache[this.curIndex] && !this.needInit) {
          // clearInterval(i);
          this.fileDownLoad(this.canvasCache[this.curIndex]);
          this.loading = false;
          // }
          // }, 200);
        } else {
          const el = this.$refs['inviteCardTmplComp' + this.curIndex][0]?.$el;
          if (!el) {
            this.loading = false;
            return;
          }
          const rect = el.getBoundingClientRect();
          if (!rect || !rect.width || !rect.height) return;
          html2canvas(el, {
            timeout: 500,
            allowTaint: true,
            useCORS: true,
            logging: false,
            width: Math.floor(rect.width),
            height: Math.floor(rect.height) - 1, //减掉1px的边框
            background: '#fff',
            scale: 1,
            ignoreElements(e) {
              console.dir(e);
              // if (e.tagName === 'LINK') {
              //   return true;
              //   // if (e.href.indexOf('invitation-card-tmpl') > -1) {
              //   //   return false;
              //   // } else {
              //   //   return true;
              //   // }
              // }
              if (e.tagName === 'SCRIPT') {
                // if (e.src.indexOf('localhost') > -1) {
                //   return false;
                // } else {
                return true;
                // }
              }
              return false;
            }
          })
            .then(canvas => {
              const canvasImgUrl = canvas.toDataURL();
              this.canvasCache[this.curIndex] = canvasImgUrl;
              this.fileDownLoad(canvasImgUrl);
            })
            .catch(err => {
              console.log(err);
            })
            .finally(e => {
              this.loading = false;
            });
        }
      },
      // 本地下载
      fileDownLoad(imgUrl, name) {
        // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
        if (window.navigator.msSaveOrOpenBlob) {
          var bstr = atob(imgUrl.split(',')[1]);
          var n = bstr.length;
          var u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          var blob = new Blob([u8arr]);
          window.navigator.msSaveOrOpenBlob(blob, `邀约海报.png`);
        } else {
          // 这里就按照chrome等新版浏览器来处理
          const a = document.createElement('a');
          a.href = imgUrl;
          a.setAttribute('download', `${this.posterName || ''}邀约海报.png`);
          a.click();
        }
      }
    },
    mounted() {}
  };
</script>

<style lang="less" scoped>
  .wujie-select-poster {
    .noData {
      width: 100%;
      img {
        width: 200px;
        margin: 20px auto;
        display: block;
      }
      .text {
        margin: 40px auto;
        text-align: center;
        font-size: 16px;
        span {
          color: #3562fa;
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }

    .invitation-card-pc {
      width: 750px;
      height: 1334px;
      // zoom: 0.25;
      transform: scale(0.25);
      transform-origin: top left;
      border-radius: 16px;
      border: 1px solid #e2e2e2;
      overflow: hidden;
    }
  }
</style>
<style lang="less">
  .wujie-select-poster {
    .vh-dialog__header {
      text-align: left;
      padding-bottom: 12px;
      font-weight: 500;
    }
    .btn {
      display: block;
      margin: 0 auto;
    }
    .vh-carousel__container {
      // width: 333.5px;
    }
    .vh-carousel {
      padding: 8px 0 22px;
    }
    .vh-carousel__item {
      width: 187.5px;
    }
    .vh-carousel__indicators {
      display: none;
    }
    .vh-carousel__item--card.is-active {
      filter: drop-shadow(-3px 10px 10px rgba(0, 0, 0, 0.15));
      opacity: 1;
    }
    .vh-carousel__item {
      background-color: #fff;
      opacity: 0.8;
      .invitation-card-pc {
        border: none;
        border-radius: 32px;
      }
    }
  }
</style>
