<template>
  <vh-dialog
    title="选择专题"
    width="600px"
    style="overflow: hidden"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelSelect"
    custom-class="choose-gift"
    :destroy-on-close="true"
  >
    <div slot="title" class="title">
      选择专题
      <div class="subTitle">请确认专题下的直播都已添加至站点</div>
    </div>
    <vh-input
      style="width: 230px"
      v-model="keyword"
      round
      placeholder="请输入专题标题"
      @keyup.enter.native="inputChange"
      @clear="inputChange"
      class="head-btn search resetRightBrn"
      :clearable="true"
    >
      <i
        slot="prefix"
        class="vh-icon-search vh-input__icon"
        :class="{ disabled: !keyword }"
        @click="inputChange(true)"
      ></i>
    </vh-input>
    <div class="select-matrial-wrap">
      <div class="material-box" v-show="total">
        <vh-scrollbar style="height: 100%" ref="vhScrollbar" v-loadMore="moreLoadData">
          <div
            class="vh-chose-active-item"
            v-for="item in liveList"
            :key="item.id"
            @click="doSelect(item)"
            :class="{ checkedActive: item.checked }"
          >
            <label class="img-tangle" v-show="item.checked">
              <img :src="require('@/common/images/icon-choose.png')" alt="" />
            </label>
            <div class="vh-chose-active-item__cover">
              <img :class="`img_box_bg box_bg_${item.itemMode}`" :src="item.cover" alt="" />
              <div class="vh-chose-active-item__cover-status">
                <span class="liveTag">
                  <span class="iconfont-v3 saasicon_lives1"></span>
                  {{ item.webinar_num }}
                </span>
              </div>
              <span class="tag" v-if="item.type == 3">双语</span>
              <!-- <div class="vh-chose-active-item__cover-hots">
                <i class="iconfont-v3 saasicon_redu">{{ item.pv | formatNum }}</i>
              </div> -->
            </div>
            <div class="vh-chose-active-item__title">
              {{ item.title }}
            </div>
            <div class="vh-chose-active-item__info">
              {{ item.created_at | unitTime }}
            </div>
          </div>
        </vh-scrollbar>
      </div>
      <div class="no-live" v-show="!total">
        <noData :nullType="nullText" :text="text" :height="50">
          <vh-button type="primary" round @click="openNewTab" v-if="nullText === 'nullData'">
            创建专题
          </vh-button>
        </noData>
      </div>
    </div>
    <div class="control">
      <!-- <span>
        当前选中
        <span class="choosed-num">{{ selectedOption.length }}</span>
        个专题
      </span> -->
      <div class="control-btn" style="text-align: right">
        <vh-button type="info" plain @click="cancelSelect" round>取消</vh-button>
        <vh-button
          @click="saveSelect"
          v-preventReClick
          :disabled="!selectedOption.length"
          type="primary"
          round
        >
          确定
        </vh-button>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, parseQueryString } from '@/utils/utils';

  export default {
    name: 'wujieSelectSubject',
    props: {
      // 选中的数组
      checkedList: {
        required: false,
        type: Array,
        default: () => []
      },
      // 选中的总数
      checkedTotal: {
        required: false,
        type: Number,
        default: 0
      },
      // 活动ID
      webinar_id: {
        default: ''
      },
      //专题类型 1:普通; 3:双语; 空:all
      subjectType: {
        default: ''
      }
    },
    data() {
      return {
        hasDelayPermission: false,
        page: 1,
        maxPage: 0,
        nullText: 'nullData',
        text: '直播间暂无绑定专题',
        total: 1,
        liveList: [],
        selectedOption: [],
        keyword: '',
        loading: true,
        pageInfo: {
          page: 1,
          limit: 6,
          pos: 0
        },
        lock: false,
        visible: false,
        isSearch: false,
        hasSame: true //用于比配初始选中值
      };
    },
    components: {
      noData
    },
    created() {},
    watch: {
      visible() {
        //获取专题
        if (this.visible) {
          this.pageInfo.page = 1;
          this.pageInfo.pos = 0;

          // this.selectedOption =
          //   (this.checkedList.length && JSON.parse(JSON.stringify(this.checkedList))) || [];
          // console.log(this.selectedOption, '???123124');
          this.getActiveList(true);
        } else {
          this.keyword = '';
          this.pageInfo.page = 1;
          this.pageInfo.pos = 0;
          this.hasSame = true;
          this.selectedOption = [];
          this.liveList.map(e => {
            e.checked = false;
          });
        }
      }
    },
    mounted() {
      const SAAS_VS_PES = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
      this.hasDelayPermission = SAAS_VS_PES
        ? JSON.parse(SAAS_VS_PES)['no.delay.webinar'] == '1'
        : false;
      console.log(this.$refs.vhScrollbar);
    },
    methods: {
      inputChange(isSearch) {
        this.liveList = [];
        this.pageInfo = {
          pos: 0,
          page: 1,
          limit: 6
        };
        this.getActiveList(true);
      },
      handleClose(done) {
        this.pageInfo.page = 1;
        done();
      },
      moreLoadData() {
        if (this.pageInfo.page >= this.maxPage) {
          return false;
        }
        this.pageInfo.page++;
        this.pageInfo.pos = parseInt((this.pageInfo.page - 1) * this.pageInfo.limit);
        this.getActiveList();
      },
      getActiveList(isClear = false) {
        const data = {
          title: this.keyword,
          webinar_id: this.webinar_id,
          type: this.subjectType,
          ...this.pageInfo
        };
        this.loading = true;

        this.$fetch('subjectList', this.$params(data))
          .then(res => {
            res.data.total &&
              res.data.list.map(item => {
                if (item.cover) {
                } else {
                  item.cover = `${env.staticLinkVo.tmplDownloadUrl}/img/v35-subject.png`;
                }
                item.itemMode = this.handlerImageInfo(item.cover);
                if (this.hasSame) {
                  if (this.checkedList[0] == item.id) {
                    this.selectedOption = [item];
                    this.hasSame = false;
                  }
                }
              });
            if (isClear) {
              this.liveList = [];
            }
            this.liveList.push(...res.data.list);
            this.total = res.data.total;
            this.maxPage = Math.ceil(res.data.total / this.pageInfo.limit);
            if (!this.keyword) {
              // 默认状态
              this.nullText = 'nullData';
              this.isSearch = false;
              this.text = '直播间暂无绑定专题';
            } else {
              // 搜索状态
              this.nullText = 'search';
              this.isSearch = true;
              this.text = '';
            }
            this.syncCheckStatus();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      // 同步 选中状态
      syncCheckStatus() {
        if (this.selectedOption.length != 0) {
          this.liveList = this.liveList.map(item => {
            if (this.selectedOption[0].id == item.id) {
              return {
                ...item,
                checked: true
              };
            } else {
              return {
                ...item
              };
            }
          });
        }
      },

      // 解析图片地址
      handlerImageInfo(url) {
        let obj = parseQueryString(url);
        return Number(obj.mode) || 3;
      },

      doSelect(item) {
        console.log(item);
        this.liveList.map(e => {
          e.checked = false;
        });
        item.checked = !item.checked;
        this.selectedOption = [item];
        this.hasSame = false;
      },

      saveSelect() {
        this.visible = false;
        this.$emit('selected', ...this.selectedOption);
      },

      cancelSelect() {
        this.visible = false;
        this.keyword = '';
        this.$emit('cacelSelect');
      },
      // 打开专题
      openNewTab() {
        const { href } = this.$router.resolve({
          path: '/special/edit',
          query: { title: '创建', type: this.subjectType == 3 ? 2 : 1 }
        });
        window.open(href, '_blank');
      }
    }
  };
</script>
<style lang="less" scoped>
  .select-matrial-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 350px;
    padding-top: 16px;
    overflow: hidden;
    .material-box {
      height: 327px;
      margin-bottom: 10px;
    }

    .vh-chose-active-item {
      cursor: pointer;
      display: inline-block;
      width: 168px;
      height: 150px;
      overflow: hidden;
      background: #f7f7f7;
      border-radius: 4px;
      // margin-bottom: 12px;
      margin: 0 12px 12px 0;
      border: 1px solid #ffffff;
      position: relative;
      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }
      .img-tangle {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 20px;
        height: 20px;
        font-size: 0;
        z-index: 100;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &.checkedActive {
        border: 1px solid #fb3a32;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
      }
      &__cover {
        position: relative;
        width: 100%;
        height: 93px;
        background: #1a1a1a;
        background-size: 400% 400%;
        animation: gradientBG 15s ease infinite;
        .img_box_bg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
          &.box_bg_1 {
            object-fit: fill;
          }
          &.box_bg_2 {
            object-fit: cover;
            object-position: left top;
          }
        }
        &-status {
          position: absolute;
          left: 8px;
          top: 8px;
          // width: 110px;
          height: 20px;
          line-height: 20px;
          background: rgba(0, 0, 0, 0.65);
          border-radius: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          img {
            height: 8px;
          }
        }
        &-hots {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 30px;
          width: 100%;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
          // left: 10px;
          // bottom: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          i {
            position: absolute;
            padding-left: 10px;
            padding-top: 5px;
          }
          // line-height: 20px;
        }
        .tag {
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          font-size: 12px;
          padding: 3px 8px;
          border-radius: 20px;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
      &__title {
        margin: 8px 8px 4px 8px;
        line-height: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &__info {
        margin: 0px 8px 8px 8px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
      .liveTag {
        // background: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-size: 12px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        span {
          margin-right: 5px;
        }
      }
    }
  }
  .subject_tip {
    position: absolute;
    top: 32px;
    left: 120px;
    color: #999;
  }
  .control {
    padding-top: 24px;
    width: 100%;
    position: relative;
    & > span {
      display: inline-block;
      line-height: 40px;
    }
    ::v-deep .disabled {
      opacity: 0.5;
    }
    .control-btn {
    }
    .choosed-num {
      color: #fb3a32;
    }
  }
  ::v-deep .choose-gift {
    .head-btn.vh-input {
      width: 220px;

      .vh-icon-search {
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
<style lang="less">
  .vh-scrollbar__wrap::-webkit-scrollbar-track {
    display: none;
  }
  .vh-dialog__header {
    .title {
      line-height: 26px;
      font-size: 18px;
      font-weight: 500;
      color: #262626;
      display: flex;
      align-items: center;
    }
    .subTitle {
      font-size: 14px;
      font-weight: 400;
      color: #999;
      margin-left: 8px;
      line-height: 20px;
    }
  }
</style>
