import { bus } from 'wujie';
import hostMap from '../../wujie/hostMap';
import wujieSelectVideo from '@/components/wujieSelectVideo';
import wujieSelectWord from '@/components/wujieSelectWord';
import wujieSelectForm from '@/components/wujieSelectForm';
import wujieSelectLive from '@/components/wujieSelectLive';
import wujieSelectSubject from '@/components/wujieSelectSubject';
import wujieSelectChannel from '@/components/wujieSelectChannel';
import wujieChannelFans from '@/components/wujieSelectChannel/channelFans.vue';
import wujieSelectPoster from '@/components/wujieSelectPoster';
import WujieInvitationCard from '@/components/wujieInvitationCard';
import wujieWeChatOfficialAccSetDialog from '@/components/wujieWeChatOfficialAccSetDialog';

export default {
  data() {
    return {
      cmsUrl: '',
      sourceId: '', //弹窗来源id
      // 音视频
      videoSize: '500.1',
      videoType: 'mp4,avi,flv,rmvb,wmv,mkv,mov',
      videoCheckedKey: 'id',
      videoEditId: '',
      // 直播
      selectedLives: [],
      liveEditId: '',
      // 文档
      selectedWords: [],
      // 音视频
      selectedVideos: [],
      // 表单
      selectedForms: [],
      // 专题
      selectedSubjects: [],
      // 渠道
      selectedChannels: [],
      //禁止选列表
      disabledList: [],
      isShowLoading: true,
      webinar_id: '',
      source_type: 2, //来源类型，source_id不为空时必填，1=直播活动 2=营销活动eventhub 3=直播专题
      scene_type: 1, // 场景类型（业务自定义） 10 聚合站点 20 系列会 30 单项活动  0=报名表单
      channel_code: '',
      posterName: '',
      zIndex: 0,
      inviteCard: {
        cardId: 0,
        defaultCardInfo: {},
        inviteSourceId: 0,
        inviteSourceType: 1,
        shareUrl: '',
        languageType: 0
      },
      qrcodeUrl: '',
      subjectType: '',

      // event公众号引流设置数据
      eventWechatOaData: undefined
    };
  },
  components: {
    wujieSelectVideo,
    wujieSelectWord,
    wujieSelectForm,
    wujieSelectLive,
    wujieSelectSubject,
    wujieSelectChannel,
    wujieChannelFans,
    wujieSelectPoster,
    WujieInvitationCard,
    wujieWeChatOfficialAccSetDialog
  },
  watch: {
    ['$route']: {
      handler(val) {
        console.log('----主应用路由更改----', val);
        // /cms/index
        const pathFlag = '/cms';
        const cmsPath = '/saas-web-cms';
        const cmsIndex = val.fullPath.indexOf(pathFlag);
        if (cmsIndex > -1) {
          this.cmsUrl = `${hostMap('CMS')}v3${cmsPath}${val.fullPath.substring(
            cmsIndex + pathFlag.length
          )}`;
          // this.cmsUrl = `${hostMap('CMS')}${val.substring(cmsIndex + pathFlag.length + 1)}`;
          console.log('----子应用路由更改----', this.cmsUrl);
          console.log('----子应用路由更改2----', val);
        }
      },
      immediate: true
    }
  },
  methods: {
    initBusEvent() {
      // 解决 子应用 弹框 无法锁定body，造成页面滚动
      bus.$on('sub-body-style-lock', value => {
        if (value) {
          document.body.classList.add('vh-popup-parent--hidden');
        } else {
          document.body.classList.remove('vh-popup-parent--hidden');
        }
      });
      bus.$on('sub-loading-change', value => {
        this.isShowLoading = value; // true 显示loading, false 隐藏loading
      });
      bus.$on(
        'sub-mediaSelect-open',
        ({
          selected = [],
          isSelectAudio = true,
          videoCheckedKey,
          sourceId,
          editId,
          videoSize,
          isMultiSelect = false
        }) => {
          if (this.$refs.selecteMedia) {
            this.sourceId = sourceId;
            this.videoType = isSelectAudio ? '' : 'mp4,avi,flv,rmvb,wmv,mkv,mov';
            this.videoCheckedKey = videoCheckedKey;
            if (editId) {
              this.selectedVideos = [editId];
              this.disabledList = selected.filter(item => item != editId);
            } else {
              this.selectedVideos = selected;
              this.disabledList = selected;
            }
            if (videoSize === undefined) {
              this.videoSize = '500.1';
            } else {
              this.videoSize = videoSize;
            }
            this.$refs.selecteMedia.open(isMultiSelect);
          }
        }
      );
      bus.$on('sub-wordSelect-open', ({ selected = [], sourceId }) => {
        if (this.$refs.selecteWord) {
          this.sourceId = sourceId;
          this.selectedWords = selected || [];
          this.$nextTick(() => {
            this.$refs.selecteWord && this.$refs.selecteWord.initComp();
          });
        }
      });
      bus.$on('sub-formSelect-open', ({ selected = [], sourceId }) => {
        if (this.$refs.selecteForm) {
          this.sourceId = sourceId;
          this.selectedForms = selected || [];
          this.$refs.selecteForm.dataBaseVisible = true;
        }
      });
      bus.$on(
        'sub-liveSelect-open',
        ({ selected = [], editId, sourceId, isMultiSelect = false }) => {
          if (this.$refs.selectLive) {
            this.sourceId = sourceId;
            if (editId) {
              isMultiSelect = false;
              this.selectedLives = [editId];
              this.disabledList = selected.map(item => item + '').filter(item => item != editId);
            } else {
              this.selectedLives = selected.map(item => item + '');
              this.disabledList = selected.map(item => item + '');
            }

            this.$refs.selectLive.open(isMultiSelect);
          }
        }
      );
      bus.$on('sub-subjectSelect-open', ({ selected = [], sourceId, webinar_id, type }) => {
        if (this.$refs.selectSubject) {
          console.log('webinar_id=》', webinar_id);
          this.sourceId = sourceId;
          this.webinar_id = webinar_id;
          this.selectedSubjects = selected || [];
          this.subjectType = type;
          this.$refs.selectSubject.visible = true;
        }
      });
      bus.$on(
        'sub-channelSelect-open',
        ({ selected = [], sourceId, webinar_id, source_type, scene_type, zIndex }) => {
          if (this.$refs.selecteChannel) {
            console.log('webinar_id=》', webinar_id);
            this.sourceId = sourceId;
            this.zIndex = zIndex;
            this.webinar_id = webinar_id;
            this.source_type = source_type || 2;
            this.scene_type = scene_type || 1;
            this.selectedChannels = selected || [];
            this.$nextTick(() => {
              this.$refs.selecteChannel && this.$refs.selecteChannel.initComp();
            });
          }
        }
      );
      bus.$on(
        'sub-channelFans-open',

        ({ sourceId, webinar_id, source_type, scene_type, zIndex }) => {
          if (this.$refs.channelFans) {
            console.log('webinar_id=》', webinar_id);
            this.sourceId = sourceId;
            this.zIndex = zIndex;
            this.webinar_id = webinar_id;
            this.source_type = source_type || 2;
            this.scene_type = scene_type || 1;
            this.$nextTick(() => {
              this.$refs.channelFans && this.$refs.channelFans.open();
            });
          }
        }
      );
      bus.$on(
        'sub-posterSelect-open',
        ({ sourceId, webinar_id, source_type, channel_code, posterName, zIndex, qrcodeUrl }) => {
          if (this.$refs.selectePoster) {
            this.sourceId = sourceId;
            this.zIndex = zIndex;
            this.webinar_id = webinar_id;
            this.channel_code = channel_code;
            this.posterName = posterName;
            this.qrcodeUrl = qrcodeUrl;
            this.source_type = source_type || 2;
            this.$nextTick(() => {
              this.$refs.selectePoster && this.$refs.selectePoster.openInit();
            });
          }
        }
      );
      // http://wiki.vhallops.com/pages/viewpage.action?pageId=333479962
      bus.$on(
        'sub-inviteCard-open',
        ({
          sourceId,
          // 邀请卡id，如果是编辑才会传
          cardId,
          defaultCardInfo,
          // sourceType对应的id，webcasting 为活动id，event 为站点id
          inviteSourceId,
          // 数据来源id 类型 1.活动 2.落地页
          inviteSourceType,
          // 分享的链接地址
          shareUrl,
          // 语言类型
          languageType = 0
        }) => {
          console.log('wujie子应用唤起邀请卡', {
            sourceId,
            cardId,
            defaultCardInfo,
            inviteSourceId,
            languageType,
            inviteSourceType,
            shareUrl
          });

          if (this.$refs.selectSubject) {
            this.sourceId = sourceId;
            this.inviteCard = {
              cardId: cardId || 0,
              defaultCardInfo: defaultCardInfo || {},
              inviteSourceId,
              inviteSourceType,
              languageType,
              shareUrl
            };
            this.$refs.invitationCard.visible = true;
          }
        }
      );
      // 聚合站点的统计按钮, 跳转datahub站点分析
      bus.$on('web-site-analysis', site => {
        const created_at = site.created_at.split(' ')[0];
        this.$router.push(`/datahub/site/${site.id}?created_at=${created_at}`);
      });

      // 公众号引流
      bus.$on('sub-wujieWeChatOfficialAccSetDialog-open', value => {
        this.eventWechatOaData = value;
        if (this.$refs.wujieWeChatOfficialAccSetDialog) {
          this.$refs.wujieWeChatOfficialAccSetDialog.visible = true;
        }
      });
    },
    jump(options = {}) {
      this.$router.push(options);
    },
    // 选择回调
    eventSelected(selectedActives, type) {
      console.log(selectedActives, '【选择】-[' + type + 'Selected]==>', this.sourceId, {
        selectedActives,
        sourceId: this.sourceId
      });
      bus.$emit('sub-' + type + 'Select-selected', {
        selectedActives,
        sourceId: this.sourceId
      });
    },
    inviteCardSave(data) {
      bus.$emit('sub-inviteCard-save', {
        data,
        sourceId: this.sourceId
      });
    }
  },
  mounted() {
    this.initBusEvent();
    // setTimeout(() => {
    //   bus.$emit('sub-posterSelect-open', {
    //     selected: [1, 21],
    //     sourceId: '',
    //     webinar_id: '290415849',
    //     source_type: 1,
    //     zIndex: 99
    //   });
    // }, 3000);
  }
};
