<template>
  <div class="wujie-select-channel">
    <!-- 文档资料库 -->
    <VhallDialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :z-index="zIndex ? zIndex : null"
      @close="dialogVisible = false"
      width="600px"
    >
      <div class="dialog-header" slot="title">
        <h3 class="header-title">
          公众号粉丝数
          <vh-tooltip effect="dark" placement="top" v-tooltipMove>
            <div slot="content">
              <p>1.数据更新频率：10分钟</p>
              <p>2.新增粉丝数：新关注公众号的用户数量，数据去重</p>
              <p>
                3.使用公众号中的预约引导关注或强制报名关注功能，观众成功关注公众号，方可展示对应粉丝数据
              </p>
            </div>
            <i :class="`iconfont-v3 saasicon_help_m gary`"></i>
          </vh-tooltip>
        </h3>
      </div>
      <div class="channel-list">
        <div v-if="total">
          <p class="ctrl_list">
            <span class="download-btn" @click="downloadFile">
              <i class="iconfont-v3 saasicon_download"></i>
            </span>
          </p>
          <!-- 表格 -->
          <vh-table
            ref="elTable"
            :data="dialogTableList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
            max-height="360px"
          >
            <vh-table-column label="渠道名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <p class="text">
                  {{ scope.row.channel_name }}
                </p>
              </template>
            </vh-table-column>
            <vh-table-column
              label="渠道参数"
              prop="channel_code"
              :show-overflow-tooltip="true"
            ></vh-table-column>
            <vh-table-column label="新增粉丝数" prop="new_fans_count"></vh-table-column>
            <div slot="empty">
              <NullPage :nullType="'search'" v-if="!total" :height="60"></NullPage>
            </div>
          </vh-table>
        </div>
        <template v-else>
          <div class="no-live" v-show="!total">
            <NullPage :nullType="'nullData'" :text="`暂无数据`" :height="10"></NullPage>
          </div>
        </template>
      </div>
    </VhallDialog>
  </div>
</template>
<script>
  import NullPage from '@/views/PlatformModule/Error/nullPage.vue';
  export default {
    components: {
      NullPage
    },
    props: {
      source_type: {
        type: Number,
        default: 1
      },
      scene_type: {
        type: Number,
        default: 1
      },
      source_id: {
        type: String,
        default: ''
      },
      zIndex: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        dialogVisible: false,
        total: -1,
        isSearch: false,
        nullText: 'search',
        dialogTableList: []
      };
    },
    methods: {
      open() {
        this.getChannelTableList();
        this.dialogVisible = true;
      },
      getChannelTableList() {
        let params = {
          source_id: this.source_id,
          source_type: this.source_type,
          scene_type: this.scene_type
        };
        this.$fetch('getChannelListLinked', this.$params(params), {
          'Content-Type': 'application/json'
        }).then(res => {
          this.dialogTableList = res.data.list;
          this.total = res.data.total;
        });
      },
      downloadFile() {
        let params = {
          source_id: this.source_id,
          source_type: this.source_type,
          scene_type: this.scene_type
        };
        this.$fetch('exportChannelFans', this.$params(params), {
          'Content-Type': 'application/json'
        })
          .then(res => {
            if (res.code == 200) {
              this.$vhMessage({
                message: `下载申请成功，${
                  this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
                }`,
                showClose: true,
                // duration: 0,
                type: 'success'
                // customClass: 'zdy-info-box'
              });
              this.$EventBus.$emit('saas_vs_download_change');
            }
          })
          .catch(res => {
            this.$vhMessage({
              customClass: 'aaa',
              type: 'error',
              message: '导出失败',
              zIndex: 5000
            });
          });
      }
    }
  };
</script>
<style scoped lang="less">
  .dialog-header {
    position: relative;
    .header-title {
      font-size: 14px;
    }
  }
  .ctrl_list {
    text-align: right;
    padding-bottom: 10px;
    .download-btn {
      cursor: pointer;
    }
  }
</style>
