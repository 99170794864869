<template>
  <div class="height-question wujie-select-form">
    <div class="show-question question-base" v-if="dataBaseVisible">
      <div class="show-main data-base">
        <p class="title">
          选择表单
          <i class="vh-icon-close" @click="dataBaseVisible = false"></i>
        </p>
        <div class="data-search" v-show="total || isSearch">
          <vh-input
            class="search-dialog-tag"
            v-clearEmoij
            v-model="keyword"
            placeholder="搜索表单名称"
            clearable
            @keyup.enter.native="getTitle"
            style="width: 220px"
            @clear="getTitle"
          >
            <i
              slot="prefix"
              class="vh-icon-search vh-input__icon"
              style="cursor: pointer; line-height: 36px"
              @click="getTitle"
            ></i>
          </vh-input>
        </div>
        <div class="data-base-list" v-show="total || isSearch">
          <vh-radio-group v-model="currentSelect">
            <vh-table
              :data="tableData"
              ref="tableList"
              style="width: 100%"
              :height="isSearch && total == 0 ? 0 : 320"
              v-loadMore="moreLoadData"
            >
              <vh-table-column width="60">
                <template slot-scope="scope">
                  <vh-radio :label="scope.row.question_id"></vh-radio>
                </template>
              </vh-table-column>
              <vh-table-column label="标题">
                <template slot-scope="scope">
                  <span class="mediaName" :title="scope.row.title">
                    {{ scope.row.title }}
                  </span>
                </template>
              </vh-table-column>
              <vh-table-column prop="updated_at" label="更新时间"></vh-table-column>
              <vh-table-column prop="topic_num" label="题目数量"></vh-table-column>
              <vh-table-column width="100" label="操作">
                <template slot-scope="scope">
                  <span class="show-hover" style="cursor: pointer" @click="preview(scope.row)">
                    预览
                  </span>
                </template>
              </vh-table-column>
            </vh-table>
          </vh-radio-group>
          <noData :nullType="'search'" :height="50" v-if="isSearch && total == 0"></noData>
        </div>
        <div class="no-live" v-show="!total && !(isSearch && total == 0)">
          <noData :nullType="'nullData'" :text="'您还没有表单，快来创建吧！'" :height="10">
            <vh-button type="primary" round @click="addQuestion" v-preventReClick>
              创建表单
            </vh-button>
          </noData>
        </div>
        <div v-show="total || (isSearch && total == 0)">
          <!-- <p class="text">
            已选择
            <span>{{ currentSelect ? 1 : 0 }}</span>
            个表单
          </p> -->
          <div slot="footer" class="dialog-footer">
            <vh-button
              type="info"
              round
              plain
              size="medium"
              @click.prevent.stop="handleCloseVisiton"
              v-preventReClick
            >
              取消
            </vh-button>
            <vh-button
              round
              size="medium"
              type="primary"
              @click.prevent.stop="choseSureQuestion"
              :disabled="!currentSelect"
              v-preventReClick
            >
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isShowQuestion">
      <div class="show-question" @click="isShowQuestion = false">
        <div class="show-main" @click.stop="isShowQuestion = true">
          <span class="close-btn">
            <i class="vh-icon-close" @click.stop="isShowQuestion = false"></i>
          </span>
          <vh-scrollbar>
            <div class="question_main">
              <pre-question :questionId="questionId"></pre-question>
            </div>
          </vh-scrollbar>
          <div class="submit-footer">
            <vh-button class="length152" type="primary" disabled size="medium" round>
              提交
            </vh-button>
          </div>
          <!-- 隐私协议合规（活动下） -->
          <!-- <privacy-select scene="question" compType="2"></privacy-select> -->
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  // import PrivacySelect from '@/views/Login/components/privacy-select.vue';
  import preQuestion from '@/components/Question/preQuestion';
  import noData from '@/views/PlatformModule/Error/nullPage';
  export default {
    name: 'wujieSelectForm',
    data() {
      return {
        total: 0,
        isSearch: false, //是否是搜索
        dataBaseVisible: false,
        isShowQuestion: false,
        loading: false,
        keyword: '',
        questionId: '',
        checkList: [],
        totalPages: 1,
        tableData: [],
        isCheckAll: false,
        pageInfo: {
          pageNum: 1,
          pos: 0,
          limit: 10
        },
        currentSelect: null
      };
    },
    props: {
      // 选中的数据
      checkedList: {
        required: false,
        type: Array,
        default: () => []
      }
    },
    components: {
      preQuestion,
      noData
      // PrivacySelect
    },
    watch: {
      dataBaseVisible() {
        if (this.dataBaseVisible) {
          this.keyword = '';
          this.currentSelect = parseInt(this.checkedList[0] + '');
          this.isCheckAll = false;
          this.pageInfo.pageNum = 1;
          this.pageInfo.pos = 0;
          this.checkList = [];
          this.tableData = [];
          this.getTableList();
        }
      }
    },
    mounted() {
      // if (this.dataBaseVisible) {
      //   this.getTableList();
      // }
    },
    methods: {
      handleClose(done) {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        done();
      },
      handleCloseVisiton() {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        this.dataBaseVisible = false;
      },
      moreLoadData() {
        if (this.pageInfo.pageNum >= this.totalPages) {
          return false;
        }
        this.pageInfo.pageNum++;
        this.pageInfo.pos = parseInt((this.pageInfo.pageNum - 1) * this.pageInfo.limit);
        this.getTableList();
      },
      handleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      getTitle() {
        this.tableData = [];
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        try {
          if (this.$refs.tableList) {
            this.$refs.tableList.clearSelection();
          }
        } catch (e) {}
        this.getTableList();
      },
      getTableList() {
        let formParams = {
          keyword: this.keyword
        };
        this.isSearch = this.keyword ? true : false;
        let obj = Object.assign({}, this.pageInfo, formParams);
        this.$fetch('getQuestionList', this.$params(obj)).then(res => {
          this.loading = false;
          this.total = res.data.total;
          let list = res.data.list || [];
          this.tableData.push(...list);
          if (this.isCheckAll) {
            this.$refs.tableList.toggleAllSelection();
          }
          console.log(this.tableData, '55555555555');
          this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
        });
      },
      // 选择资料库中的表单
      choseSureQuestion() {
        this.dataBaseVisible = false;
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;

        let res = null;
        this.tableData.map(item => {
          if (item.question_id == this.currentSelect) {
            res = item;
          }
        });
        this.$emit('selected', res);
      },
      choseShowQueston() {
        this.isShowQuestion = false;
        this.dataBaseVisible = true;
      },
      addQuestion() {
        this.$vhConfirm('创建表单会离开当前页面，将丢失已编辑信息，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,

          roundButton: true,
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            let routeUrl = this.$router.resolve({
              path: '/interactive/addQuestion',
              query: {
                type: 1
              }
            });
            window.open(routeUrl.href, '_blank');
          })
          .catch(() => {});
      },
      // 预览
      preview(rows) {
        console.log('预览', rows);
        this.questionId = rows.question_id;
        this.isShowQuestion = true;
        // this.dataBaseVisible = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .search-dialog-tag {
    ::v-deep .vh-input__prefix {
      cursor: pointer;
    }
  }
  .data-base {
    position: relative;
    z-index: 1000;
    .title {
      padding: 32px;
      font-size: 20px;
      i {
        float: right;
        color: #1a1a1a;
      }
    }
    .data-search {
      padding-left: 24px;
    }
  }

  .data-base-list {
    width: 100%;
    margin: 24px 0;
    padding: 0 32px;
    .mediaName {
      font-size: 14px;
      color: #1a1a1a;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .show-hover {
      &:hover {
        color: #fb3a32;
      }
    }
  }
  .text {
    height: 40px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 32px;
    span {
      color: #fb3a32;
      padding: 0 5px;
    }
    span {
      cursor: pointer;
    }
  }
  .show-question {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.3);
    .show-main {
      position: absolute;
      top: 48%;
      left: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 750px;
      // padding: 24px 32px;
      padding-bottom: 24px;
      border-radius: 4px;
      .question_main {
        max-height: 550px;
        position: relative;
      }
      .close-btn {
        z-index: 100;
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        padding: 5px;
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .submit-footer {
        text-align: center;
        padding-top: 20px;
      }
    }
    .data-base {
      width: 750px;
    }
  }
  .dialog-footer {
    text-align: right;
    margin-right: 30px;
  }
</style>
<style lang="less">
  .wujie-select-form {
    .vh-radio-group {
      width: 100%;
      max-width: 100%;
    }
    .vh-radio__label {
      display: none;
    }
    .vh-table td:first-child,
    .vh-table th:first-child {
      padding-left: 24px;
    }
  }
</style>
