<template>
  <vh-dialog
    :title="`${cardId ? '编辑' : '创建'}邀约海报`"
    width="920px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDialog"
  >
    <div class="invitation-card-title" slot="title">
      {{ `${cardId ? '编辑' : '创建'}邀约海报` }}
      <vh-button
        size="medium"
        plain
        round
        class="download-btn"
        :disabled="isImgsLoading"
        @click="downloadCard"
        v-if="cardId"
      >
        本地下载
      </vh-button>
    </div>

    <div class="invitation-card-warp">
      <div class="invitation-from">
        <div class="form-data">
          <invitation-card-edit
            v-if="visible"
            ref="cardEdit"
            :isOpen="true"
            @cancel="closeDialog"
            @changeCardInfo="changeCardInfo"
            @imgLoadCompleted="imgLoadCompleted"
            @inviteCardSave="inviteCardSave"
            :cardId="cardId"
            :defaultCardInfo="defaultCardInfo"
            :sourceId="sourceId"
            :sourceType="sourceType"
            :shareUrl="shareUrl"
            :languageType="languageType"
          ></invitation-card-edit>
        </div>
      </div>
      <div class="invitation-show">
        <invitation-card-tmpl
          ref="inviteCardTmplComp"
          class="invitation-card-pc"
          :cardInfo="cardInfo"
          :positionStyle="positionStyle || {}"
          @onImgsLoaded="onImgsLoadingChange"
        ></invitation-card-tmpl>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import InvitationCardEdit from '@/views/LiveModule/Invite/InvitationCard/components/InvitationCardEdit.vue'; //数据编辑
  import { InvitationCardTmpl } from '@vhcl/invitation-card';
  import '@vhcl/invitation-card/dist/css/index.css';
  export default {
    name: 'WujieInvitationCard',
    props: {
      // 邀请卡id，如果是编辑才会传
      cardId: {
        type: [Number, String],
        required: false
      },
      // 邀请卡默认信息，如果是新建才会传（cardId为空时生效）
      defaultCardInfo: {
        type: Object,
        required: false
      },
      // sourceType对应的id，webcasting 为活动id，event 为站点id
      sourceId: {
        type: Number,
        required: true
      },
      // 数据来源id 类型 1.活动 2.落地页
      sourceType: {
        type: Number,
        required: true
      },
      // 邀请卡二维码图片地址
      shareUrl: {
        type: String,
        required: true
      },
      // 语言类型
      languageType: {
        type: Number,
        required: false,
        default: 0
      }
    },
    data() {
      return {
        visible: false,
        cardInfo: {},
        positionStyle: {},
        isImgsLoading: true,
        loading: true
      };
    },
    components: {
      InvitationCardTmpl,
      InvitationCardEdit
    },
    methods: {
      imgLoadCompleted() {
        this.loading = false;
      },
      closeDialog() {
        this.visible = false;
        // reset state
        this.cardInfo = {};
        this.positionStyle = {};
        this.isImgsLoading = true;
        this.loading = true;
        console.log('关闭弹窗');
      },
      onImgsLoadingChange(val) {
        this.isImgsLoading = val;
      },
      changeCardInfo({ cardInfo, positionStyle }) {
        this.cardInfo = cardInfo;
        this.positionStyle = positionStyle;
      },
      //本地下载
      downloadCard() {
        const el = this.$refs.inviteCardTmplComp?.$el;
        this.$refs.cardEdit.drawCanvas(el);
      },
      inviteCardSave(data) {
        this.closeDialog();
        this.$emit('inviteCardSave', data);
      }
    }
  };
</script>
<style lang="less" scoped>
  .invitation-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .download-btn {
      margin-right: 40px;
    }
  }
  .invitation-card-warp {
    padding-top: 8px;
    display: flex;
    .invitation-from {
      height: 535px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      .form-data {
        width: 479px;
      }
    }

    .invitation-show {
      margin-left: 77px;
      border: 1px solid #e2e2e2;
      border-radius: 16px;
      position: relative;
      width: 300px;
      height: 535px;
      overflow: hidden;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        padding-bottom: 16px;
      }
      .invitation-card-pc {
        width: 750px;
        height: 1334px;
        transform: scale(0.4);
        transform-origin: 0 0;
      }
    }
  }
</style>
