<template>
  <vh-dialog
    title="添加直播"
    width="600px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelSelect"
    custom-class="choose-gift"
    :z-index="1000"
  >
    <vh-input
      style="width: 230px"
      v-model="keyword"
      round
      placeholder="请输入直播标题或者直播ID"
      @keyup.enter.native="inputChange"
      @clear="inputChange"
      class="head-btn search resetRightBrn"
      :clearable="true"
    >
      <i
        slot="prefix"
        class="vh-icon-search vh-input__icon"
        :class="{ disabled: !keyword }"
        @click="inputChange(true)"
      ></i>
    </vh-input>
    <div class="select-matrial-wrap">
      <div class="material-box" v-show="total">
        <vh-scrollbar style="height: 100%" v-loadMore="moreLoadData">
          <div
            class="vh-chose-active-item"
            v-for="item in activeList"
            :key="item.webinar_id"
            @click="doSelect(item)"
            :class="{ checkedActive: item.checked, checkedDisabled: item.isDisabled }"
          >
            <label class="img-tangle" v-show="item.checked || item.isDisabled">
              <img
                v-show="!item.isDisabled"
                :src="require('@/common/images/icon-choose.png')"
                alt=""
              />
              <img
                v-show="item.isDisabled"
                :src="require('@/common/images/icon-choose-disable.png')"
                alt=""
              />
            </label>
            <div class="vh-chose-active-item__cover">
              <img :class="`img_box_bg box_bg_${item.itemMode}`" :src="item.img_url" alt="" />
              <div class="vh-chose-active-item__cover-status">
                <span class="liveTag">
                  <label class="live-status" v-if="item.webinar_state == 1">
                    <img :src="require('@/common/images/live.gif')" alt="" />
                  </label>
                  {{ item | liveTag }}
                  <span
                    v-if="
                      item.webinar_type != 6 && hasDelayPermission && item.no_delay_webinar == 1
                    "
                  >
                    | 无延迟
                  </span>
                  <span v-if="item.webinar_show_type == 0">| 竖屏</span>
                </span>
              </div>
              <div class="vh-chose-active-item__cover-hots">
                <i class="iconfont-v3 saasicon_redu">{{ item.pv | formatNum }}</i>
              </div>
            </div>
            <div class="vh-chose-active-item__title">
              {{ item.subject }}
            </div>
            <div class="vh-chose-active-item__info">
              {{ item.start_time }}
            </div>
          </div>
        </vh-scrollbar>
      </div>
      <div class="no-live" v-show="!total">
        <noData :nullType="nullText" :text="text" :height="20">
          <vh-button type="primary" round @click="openNewTab" v-if="nullText === 'nullData'">
            创建直播
          </vh-button>
        </noData>
      </div>
    </div>
    <div class="control">
      <!-- <span>
        当前选中
        <span class="choosed-num">{{ selectedOption.length }}</span>
        个直播
      </span> -->
      <div class="control-btn" style="text-align: right">
        <vh-button type="info" plain @click="cancelSelect" round>取消</vh-button>
        <vh-button
          @click="saveSelect"
          v-preventReClick
          :disabled="
            isMultiSelect
              ? !selectedOption.length
              : !selectedOption.length || selectedOption[0].webinar_id == checkedList[0]
          "
          type="primary"
          round
        >
          确定
        </vh-button>
      </div>
    </div>
  </vh-dialog>
</template>
<script>
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, parseQueryString } from '@/utils/utils';

  export default {
    name: 'wujieSelectLive',
    props: {
      // 选中的数组
      checkedList: {
        required: false,
        type: Array,
        default: () => []
      },
      // 选中的总数
      checkedTotal: {
        required: false,
        type: Number,
        default: 0
      },
      //禁止选列表
      disabledList: {
        required: false,
        type: Array,
        default: () => []
      },
      // 专题权限
      checkAuth: {
        required: false,
        type: Number,
        default: 0
      },
      // 是否过滤竖屏
      filterPortraitScreenLive: {
        required: false,
        type: Boolean,
        default: false
      },
      // 是否过滤分组
      filterGroupLive: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hasDelayPermission: false,
        page: 1,
        maxPage: 0,
        nullText: 'nullData',
        text: '您还没有创建直播',
        total: 1,
        activeList: [], // 总列表
        selectedOption: [], // 最终结果列表
        keyword: '',
        loading: true,
        pageInfo: {
          page: 1,
          limit: 6,
          pos: 0
        },
        lock: false,
        visible: false,
        isSearch: false,
        isMultiSelect: false, // 是否多选
        isOpeated: false // 是否操作过列表（选中或取消选中）
      };
    },
    components: {
      noData
    },
    created() {},
    watch: {
      visible() {
        //获取资料中心的音视频
        if (this.visible) {
          this.pageInfo.page = 1;
          this.pageInfo.pos = 0;
          // console.log(this.selectedOption, '???123124');
          this.getActiveList(true);
        } else {
          this.keyWords = '';
          this.isOpeated = false;
          this.pageInfo.page = 1;
          this.pageInfo.pos = 0;
          this.selectedOption = [];
          this.activeList = [];
        }
      }
    },
    mounted() {
      const SAAS_VS_PES = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
      this.hasDelayPermission = SAAS_VS_PES
        ? JSON.parse(SAAS_VS_PES)['no.delay.webinar'] == '1'
        : false;
    },
    methods: {
      open(isMultiSelect) {
        this.visible = true;
        this.isMultiSelect = isMultiSelect;
      },
      inputChange(isSearch) {
        if (this.keyword) {
          this.$vhall_paas_port({
            k: 100509,
            data: {
              business_uid: this.$parent.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.activeList = [];
        this.pageInfo = {
          pos: 0,
          page: 1,
          limit: 6
        };
        this.getActiveList(true);
      },
      handleClose(done) {
        this.pageInfo.page = 1;
        done();
      },
      moreLoadData() {
        if (this.pageInfo.page >= this.maxPage) {
          return false;
        }
        this.pageInfo.page++;
        this.pageInfo.pos = parseInt((this.pageInfo.page - 1) * this.pageInfo.limit);
        this.getActiveList();
      },
      /**
       * http://yapi.vhall.domain/project/99/interface/api/21561
       * @param {*} isClear
       */

      getActiveList(isClear = false) {
        this.loading = true;
        const userId = sessionStorage.getItem('userId');
        let params = {
          title: this.keyword,
          subject_id: this.$route.params.id || undefined,
          order_type: 1,
          is_subject: this.checkAuth > 0 ? 1 : 0,
          webinar_state: 0,
          ...this.pageInfo
        };
        if (this.filterPortraitScreenLive) {
          params.webinar_show_type = 1; //过滤竖屏
        }
        if (this.filterGroupLive) {
          params.is_group = 1; //过滤分组直播
        }

        this.$fetch('liveList', this.$params(params)).then(res => {
          if (res.code == 200) {
            if (!this.keyword) {
              // 默认状态
              this.nullText = 'nullData';
              this.text = '你还没有创建直播！';
              this.isSearch = false;
            } else {
              // 搜索状态
              this.nullText = 'search';
              this.text = '';
              this.isSearch = true;
            }
            if (isClear) {
              this.activeList = [];
            }
            res.data.list = this.setCustomProps(res.data.list);
            this.activeList = this.activeList.concat(res.data.list);
            this.total = res.data.total;
            this.maxPage = Math.ceil(res.data.total / this.pageInfo.limit);
            this.loading = false;
            // this.syncCheckStatus();
          } else {
            this.loading = false;
          }
        });
      },
      setCustomProps(list) {
        let mode;
        if (list && list.length) {
          return list.map(item => {
            mode = this.handlerImageInfo(item.img_url);
            return {
              ...item,
              isDisabled: this.disabledList.includes(item.webinar_id + ''), // 是否禁用，单选全部不禁用
              checked: this.isMultiSelect // 如果是多选，checkedList 里面的和 selectedOptions 里面的都需要设为 checked
                ? this.checkedList.includes(item.webinar_id + '') ||
                  this.isHasItem(this.selectedOption, item)
                : this.selectedOption.length // 如果是单选，以 selectedOptions 里面的为主，如果存在就不需要关心 checkedList，不存在再看 checkedList 里面是否有
                ? this.selectedOption[0].webinar_id == item.webinar_id
                : this.checkedList.includes(item.webinar_id + ''), // 是否选中
              itemMode: mode
            };
          });
        }
        return list;
      },
      isHasItem(list = [], checkItem) {
        return list.some(item => item.webinar_id == checkItem.webinar_id);
      },
      // 解析图片地址
      handlerImageInfo(url) {
        let obj = parseQueryString(url);
        return Number(obj.mode) || 3;
      },
      // 同步 选中状态
      // syncCheckStatus() {
      //   if (this.selectedOption.length != 0) {
      //     this.activeList.forEach(item => {
      //       this.selectedOption.forEach(selectItem => {
      //         if (selectItem.webinar_id == item.webinar_id) {
      //           this.$set(item, 'checked', true);
      //         }
      //       });
      //     });
      //   }
      // },
      // 设置编辑的id设置为非选中状态

      doSelect(item) {
        this.isOpeated = true;
        if (item.isDisabled) return;
        if (!this.isMultiSelect) {
          // 处理单选的逻辑
          this.activeList.forEach(live => {
            live.checked = false;
          });
          item.checked = true;
          this.selectedOption = [item];
        } else {
          // 处理多选的逻辑
          item.checked = !item.checked;
          if (item.checked) {
            this.selectedOption.push(item);
          } else {
            this.removeSelect(item);
          }
        }
        // this.syncCheckStatus();
      },

      removeSelect(item) {
        this.selectedOption = this.selectedOption.filter(
          select => select.webinar_id != item.webinar_id
        );
      },

      saveSelect() {
        // const checkedActives = this.activeList.filter(item => item.checked)
        this.visible = false;
        // 如果是多选就是数组，单选就是 item 对象
        this.$emit('selected', this.isMultiSelect ? this.selectedOption : this.selectedOption[0]);
      },

      cancelSelect() {
        this.visible = false;
        this.keyword = '';
        this.$emit('cacelSelect');
      },

      // 打开活动
      openNewTab() {
        const { href } = this.$router.resolve({ path: '/live/edit', query: { title: '创建' } });
        window.open(href, '_blank');
      }
    }
  };
</script>
<style lang="less" scoped>
  .select-matrial-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 320px;
    margin-top: 16px;
    overflow: hidden;
    .material-box {
      height: 320px;
    }

    .vh-chose-active-item {
      cursor: pointer;
      display: inline-block;
      width: 168px;
      height: 150px;
      overflow: hidden;
      background: #f7f7f7;
      border-radius: 4px;
      // margin-bottom: 12px;
      margin: 0 12px 12px 0;
      border: 1px solid #ffffff;
      position: relative;
      &:nth-of-type(3n + 0) {
        margin-right: 0;
      }
      .img-tangle {
        position: absolute;
        right: -1px;
        top: -1px;
        width: 20px;
        height: 20px;
        font-size: 0;
        z-index: 100;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &.checkedActive {
        border: 1px solid #fb3a32;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
      }
      &.checkedDisabled {
        border: 1px solid #cccccc;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
        cursor: not-allowed;
        opacity: 0.5;
      }
      &__cover {
        position: relative;
        width: 100%;
        height: 93px;
        background: #1a1a1a;
        background-size: 400% 400%;
        animation: gradientBG 15s ease infinite;
        .img_box_bg {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
          &.box_bg_1 {
            object-fit: fill;
          }
          &.box_bg_2 {
            object-fit: cover;
            object-position: left top;
          }
        }
        &-status {
          position: absolute;
          left: 8px;
          top: 8px;
          // width: 110px;
          height: 20px;
          line-height: 20px;
          background: rgba(0, 0, 0, 0.65);
          border-radius: 100px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          img {
            height: 8px;
          }
        }
        &-hots {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 30px;
          width: 100%;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
          // left: 10px;
          // bottom: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          i {
            position: absolute;
            padding-left: 10px;
            padding-top: 5px;
          }
          // line-height: 20px;
        }
      }
      &__title {
        margin: 8px 8px 4px 8px;
        line-height: 17px;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      &__info {
        margin: 0px 8px 8px 8px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
      .liveTag {
        // background: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-size: 12px;
        padding: 2px 9px;
        border-radius: 20px;
        position: relative;
        z-index: 2;
        .live-status {
          // padding: 5px;
          img {
            // margin: 6px 7px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  .subject_tip {
    position: absolute;
    top: 32px;
    left: 120px;
    color: #999;
  }
  .control {
    padding-top: 24px;
    width: 100%;
    position: relative;
    & > span {
      display: inline-block;
      line-height: 40px;
    }
    ::v-deep .disabled {
      opacity: 0.5;
    }
    .control-btn {
      // float: right;
    }
    .choosed-num {
      color: #fb3a32;
    }
  }
  ::v-deep .choose-gift {
    .head-btn.vh-input {
      width: 220px;

      .vh-icon-search {
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
</style>
<style lang="less">
  .vh-scrollbar__wrap::-webkit-scrollbar-track {
    display: none;
  }
  .select-matrial-wrap .vh-scrollbar__wrap {
    overflow-x: hidden;
  }
</style>
