<template>
  <vh-dialog
    title="公众号设置"
    width="810px"
    top="5vh"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDialog"
    class="wechat-oa-dialog"
  >
    <OfficialAccCard
      v-if="visible"
      :eventWechatOaData="eventWechatOaData"
      @settingChange="settingChange"
    ></OfficialAccCard>
  </vh-dialog>
</template>
<script>
  import { bus } from 'wujie';
  import OfficialAccCard from '../../views/LiveModule/Brands/officialCard.vue';
  export default {
    name: 'wujieWeChatOfficialAccSetDialog',
    components: {
      OfficialAccCard
    },
    props: {
      eventWechatOaData: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        visible: false
      };
    },
    methods: {
      settingChange(scene) {
        bus.$emit('sub-wujieWeChatOfficialAccSetDialog-settingChange');
        if (scene === 1) {
          // 保存后关闭对话框
          this.closeDialog();
        }
      },
      closeDialog() {
        this.visible = false;
      }
    }
  };
</script>
<style lang="less">
  .wechat-oa-dialog {
    .vh-dialog {
      margin: 0 auto;
    }
  }
</style>
